import React from "react"

import Layout from "../components/layout"

export default () => {
  return (
    <div>
      <Layout>
        <div className="max-w-screen-xl px-4 mx-auto grid gap-20 md:grid-cols-2">
          <h1>404 - Sorry not found</h1>
        </div>
      </Layout>
    </div>
  )  
}